<template>
  <div class="index">
    <!-- 轮播图 -->
    <swiper :list="swiper_list"/>
    <!-- 产品/服务 -->
    <div class="services alCen">
      <div>
        <div class="fs24 bold cole00406">
          我们专注数字乡村，赋能乡村振兴战略实施
        </div>
        <div class="fs14 lh22 col8b8b8b mTop8">
          提供咨询、设计、软件开发、信息化整体解决方案
        </div>
      </div>
      <div class="box1">
        <div class="col8b8b8b fs12 QR">
          <img src="@/assets/image/index/QR/code.png" alt=""/>
          <div class="mTop8">扫一扫，了解更多详细方案</div>
        </div>

        <div class="col8b8b8b maLfAuto info">
          <div class="alCen">
            <img src="@/assets/img/index/1.png" alt=""/>
            <div class="maLf8">办公电话： {{ company.telephone }}</div>
          </div>
          <div class="alCen mTop12">
            <img src="@/assets/img/index/2.png" alt=""/>
            <div class="maLf8">手机： {{ company.phone }}</div>
          </div>
          <div class="alCen mTop12">
            <img src="@/assets/img/index/3.png" alt=""/>
            <div class="maLf8">全国服务热线： {{ company.telephone2 }}</div>
          </div>
        </div>

      </div>
      <!-- <div class="col8b8b8b maLfAuto">
        <div class="alCen">
          <img src="@/assets/img/index/1.png" alt="" />
          <div class="maLf8">办公电话： {{ company.telephone }}</div>
        </div>
        <div class="alCen mTop12">
          <img src="@/assets/img/index/2.png" alt="" />
          <div class="maLf8">手机： {{ company.phone }}</div>
        </div>
        <div class="alCen mTop12">
          <img src="@/assets/img/index/3.png" alt="" />
          <div class="maLf8">全国服务热线： {{ company.telephone2 }}</div>
        </div>
      </div>
      <div class="col8b8b8b fs12 QR">
        <img src="@/assets/image/index/QR/code.png" alt="" />
        <div class="mTop8">扫一扫，了解更多信息</div>
      </div> -->

    </div>
    <!-- 新闻动态 -->
    <div class="news">
      <div class="fs32 t-a-c bold news-title2">新闻资讯</div>
      <div class="news-main mTop70">
        <div class="news-left" @click="toArticle(news_list[0].id)">
          <img :src="replace_domain(news_list[0].icon)" class="hand"/>
          <div>
            <div class="alCen">
              <img src="../../assets/image/index/news/hot.jpg" class="hot"/>
              <div class="one-overflow news-title fs20 bold">
                {{ news_list[0].title }}
              </div>
            </div>
            <div class="one-overflow col333 fs14 mTop16">
              {{ news_list[0].newsDesc }}
            </div>
          </div>
<!--          <img-->
<!--              src="../../assets/image/index/product/right.png"-->
<!--              class="hand news-more"-->
<!--              @click.stop="toNews()"-->
<!--          />-->
        </div>
        <div class="news-right maLf45">
          <div
              v-for="(item, index) in news_list.slice(1, 4)"
              class="news-right-item"
              @click="toArticle(item.id)"
              :key="index"
          >
            <div class="alCen news-right-title">
              <div class="one-overflow fs16">
                {{ item.title }}
              </div>
              <div class="maLfAuto fs14 col999">
                {{
                  item.createTime.length == 10
                      ? item.createTime
                      : item.createTime.substring(0, 10)
                }}
              </div>
            </div>
            <div class="mTop14 col787878 fs14 four-overflow lh26">
              {{ item.newsDesc }}
            </div>
          </div>
        </div>
      </div>
      <div @click.stop="toNews()" class="btn-more fs16 hand" @click="toProducts">了解更多新闻</div>
    </div>


    <!-- 软件平台-->
    <div class="introduce">
      <div class="fs32 t-a-c bold news-title2">软件平台</div>

      <!-- 应用平台 -->
      <el-carousel
          :interval="5000"
          indicator-position="none"
          arrow="never"
          :autoplay="false"
          height="420"
          class="application"
          @change="app_change"
          ref="carousel"
      >
        <el-carousel-item class="application-i">
          <div class="flex wrap application-item jsSB">
            <div class="colFFF frist">
              <div class="fs24 bold">12+</div>
              <div class="fs14 bold mTop16">智慧软件应用平台</div>
              <div class="fs12 mTop8">zhi hui ruan jian ying yong</div>
            </div>
            <div
                v-for="(item, index) in application_list.slice(0, 6)"
                :key="index"
                :class="item.class"
                class="hand"
                @click="toProduct(item.name)"
            >
              <img :src="item.icon" alt=""/>
              <div class="colFFF t-a-c fs16 bold">
                {{ item.title }}
              </div>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item class="application-i">
          <div class="flex wrap application-item2 jsSB">
            <div
                v-for="(item, index) in application_list.slice(6, 13)"
                :key="index"
                :class="item.class"
                class="hand"
                @click="toProduct(item.name)"
            >
              <img :src="item.icon" alt=""/>
              <div class="colFFF t-a-c fs16 bold">
                {{ item.title }}
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="f-a-j mTop8">
      <img
          :src="application_index != application_l ? l : l_c"
          class="btn-arrow hand"
          @click="$refs.carousel.setActiveItem(0)"
      />
      <img
          :src="application_index != application_l ? r_c : r"
          class="btn-arrow maLf16 hand"
          @click="$refs.carousel.setActiveItem(1)"
      />
    </div>
    <!-- 数字乡村与乡村振兴 -->
    <div class="figure">
      <div class="t-a-c fs32 bold figure-title">数字乡村与乡村振兴</div>
      <div
          class="alCen-jcSB figure-item colFFF mTop60 hand"
          @click="toProduct('数字乡村解决方案')"
      >
        <div>
          <div class="fs18 bold">数字乡村解决方案</div>
          <div class="fs12 mTop8">赋能乡村振兴实施落地，服务好三农工作</div>
        </div>
        <img src="@/assets/img/index/jiantouxuanzhong_png.png" alt=""/>
      </div>
      <div
          class="alCen-jcSB figure-item2 mTop32 hand"
          @click="toProduct('乡村振兴大数据平台')"
      >
        <div>
          <div class="fs18 bold">乡村振兴大数据平台</div>
          <div class="fs12 mTop8">建设数字乡村，助力乡村振兴</div>
        </div>
        <img src="@/assets/img/index/jiantou_png.png" alt=""/>
      </div>
    </div>
    <!-- 公司优势 -->
    <div class="advantage">
      <div class="t-a-c advantage-title">
        <div class="fs32 bold">公司优势</div>
        <div class="col8b8b8b">秉承初心、极致创新</div>
      </div>
      <div class="advantage-list colFFF mTop32 alCen" style="justify-content: space-between;padding:0 40px;">
        <div
            v-for="(item, index) in advantage"
            :key="index"
            class="advantage-item"
        >
          <img :src="item.icon" alt=""/>
          <div class="fs18 bold t-a-c mTop16">
            {{ item.title }}
          </div>
          <div class="fs16 lh22 mTop16" >
            {{ item.content }}
          </div>
        </div>
      </div>
    </div>
    <!-- 公司政策 -->
    <!--    <div class="product">-->
    <!--      <div class="t-a-c product-title">-->
    <!--        <div class="fs32 bold">公司政策</div>-->
    <!--        <div class="col8b8b8b">汇聚科技精华,缔造百年和财</div>-->
    <!--      </div>-->
    <!--      <div class="flex mTop16">-->
    <!--        <div class="maLfAuto maRh145" style="width: 38%">-->
    <!--          <div v-for="(item, index) in policy" :key="index" class="mTop45">-->
    <!--            <div class="fs24 bold cole00406">-->
    <!--              {{ item.title }}-->
    <!--            </div>-->
    <!--            <div class="mTop8 fs14">{{ item.content }}</div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <!-- 产品介绍 -->
    <div class="introduce">
      <div class="introduce-title fs32 bold t-a-c">产品介绍</div>

      <div class="introduce colFFF">
        <div
            v-for="(item, index) in product_list.slice(0, 3)"
            :key="index"
            class="alCen-jcSB introduce-item"
            @click="toProduct(item.title)"
        >
          <div>
            <div class="bold fs24">
              {{ item.title }}
            </div>
            <div class="fs12 mTop32 lh22 three-overflow">
              {{ item.productDesc }}
            </div>
            <div class="hand mTop40 detail" :class="'detail' + index">
              查看详情
            </div>
          </div>
          <img :src="item.icon" alt="" :class="{ order0: index == 1 }"/>
        </div>
      </div>
    </div>
    <div class="btn-more fs16 hand" @click="toProducts">了解更多产品</div>


    <!-- 开始 -->
    <div class="start">
      <div class="fs32 t-a-c">数字乡村,从现在开始</div>
      <div class="start-more hand" @click="toScheme('数字乡村建设方案')">
        了解更多详细方案
      </div>
      <div class="t-a-c start-line f-a-j">有任何疑问,欢迎咨询</div>
      <div class="f-a-j mTop50 fs18 bold">
        <div class="alCen">
          <img :src="company.telephone_none_line_icon" class="start-icon"/>
          <div
              class="maLf16"
              :style="{ color: company.telephone_none_line_fontColor }"
          >
            {{
              company.telephone_none_line
            }}{{ company.telephone_none_line_fontColor }}
          </div>
        </div>
        <div class="alCen maLf90">
          <img :src="company.phone_blank_icon" class="start-icon"/>
          <div class="maLf16" :style="{ color: company.phone_blank_fontColor }">
            {{ company.phone_blank }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import swiper from "./components/swiper.vue";

export default {
  name: "index",
  components: {
    swiper,
  },
  data() {
    return {
      company: this.$store.state.company,
      swiper_list: [
        {
          imgUrl: require("../../assets/image/index/swiper/1.jpg"),
        },
        {
          imgUrl: require("../../assets/image/index/swiper/2.jpg"),
        },
      ],
      application_list: [
        {
          icon: require("../../assets/img/index/1/1.jpg"),
          title: "农村电商",
          name: "农村电商",
          class: "app1",
        },
        {
          icon: require("@/assets/img/index/1/2.png"),
          title: "乡村旅游",
          class: "app2",
          name: "智慧旅游",
        },
        {
          icon: require("../../assets/img/index/1/3.png"),
          title: "四好农村公路管养平台",
          name: "智慧公路",
          class: "app3",
        },
        {
          icon: require("@/assets/img/index/1/13.jpg"),
          title: "智慧农业",
          name: "智慧农业",
          class: "app4",
        },
        {
          icon: require("../../assets/img/index/1/5.jpg"),
          title: "组织建设",
          name: "组织建设",
          class: "app5",
        },
        {
          icon: require("../../assets/img/index/1/6.png"),
          title: "农村人居环境整治平台",
          name: "农村人居环境整治平台",
          class: "app6",
        },
        {
          icon: require("../../assets/img/index/1/7.jpg"),
          title: "考勤管理",
          name: "驻村干部考勤管理",
          class: "app7",
        },
        {
          icon: require("../../assets/img/index/1/8.png"),
          title: "远程教育",
          name: "农业农村远程教育平台",
          class: "app8",
        },
        {
          icon: require("../../assets/img/index/1/9.jpg"),
          title: "精准扶贫",
          name: "精准扶贫",
          class: "app9",
        },
        {
          icon: require("@/assets/img/index/1/4.png"),
          title: "三治合一",
          name: "三治合一",
          class: "app13",
        },
        {
          icon: require("../../assets/img/index/1/10.jpg"),
          title: "生态监测",
          name: "智慧生态",
          class: "app10",
        },
        {
          icon: require("../../assets/img/index/1/11.jpg"),
          title: "应急广播",
          name: "应急广播",
          class: "app11",
        },
        {
          icon: require("../../assets/img/index/1/12.jpg"),
          title: "产权交易",
          name: "农村产权交易平台",
          class: "app12",
        },
      ],
      application_l: 0,
      application_index: 1,
      application_scheme: [
        {
          img_url: require("../../assets/image/index/application/13.png"),
          title: "数字乡村农业产业建设解决方案",
        },
        {
          img_url: require("../../assets/image/index/application/14.png"),
          title: "乡村振兴",
        },
      ],
      innovate_list: [
        {
          img_url: require("../../assets/image/index/innovate/1.png"),
        },
        {
          img_url: require("../../assets/image/index/innovate/2.png"),
        },
        {
          img_url: require("../../assets/image/index/innovate/3.png"),
        },
        {
          img_url: require("../../assets/image/index/innovate/4.png"),
        },
      ],
      dream_img: require("../../assets/image/index/background/2.png"),
      dream_list: [
        {
          title: "○积极响应国家号召",
          describe:
              "响应国家号召、认真学习十四五规划指导思想,赋能乡村振兴、数字乡村建设全面实施提供专业的解决方案",
        },
        {
          title: "●技术创新实施落地",
          describe:
              "基于5G互联网、北斗卫星导航技术、大数据、云计算、人工智能和物联网等技术自主研发了多个大型智慧软件云平台",
        },
        {
          title: "致力服务好各级部门",
          describe:
              "我们专注数字乡村，提供咨询、设计、软件开发、信息化整体解决方案，赋能乡村振兴战略实施。",
        },
      ],
      product_list: [
        {
          icon: require("../../assets/image/index/product/1.jpg"),
          title: "直播助农，助力乡村振兴，助推共同富裕",
          productDesc:
              "新时期，党和国家全面领导，促进共同富裕，推进现代化强国建设和民族伟大复兴梦的实现。而乡村振兴是实现共同富裕的重要战略部署，没有乡村振兴，就没有共同富裕。",
        },
        {
          title: "驻村干部考勤管理",
          productDesc:
              "驻村干部考勤管理平台系统概述为了进一步提高驻村工作的效率和管理水平，解决驻村工作中“请假难”、",
          icon: require("../../assets/image/index/product/2.jpg"),
        },
        {
          title: "推进数字乡村建设，助推乡村振兴",
          productDesc:
              "近年来，互联网、大数据、云计算、人工智能等技术加速创新，日益融入经济社会发展全过程。世界主要国家和地区纷纷加快推进数字化战略转型布局。加快数字化转型是“十四五”时期建设网络强国和数字中",
          createTime: "2022-01-29",
        },
        {
          title: "高水平建设“四好农村路”，助推乡村振兴",
          productDesc:
              "一条柏油路，或沥青，或水泥，从普通农家、村庄、城镇，一直连通到县甚至更大的城市。正是通过这条路上，农民们把新鲜的农产品运进城，将生活必需品带回家。十八大以来，国家主席习近平高度重视农",
          createTime: "2022-01-17",
        },
        {
          title: "全面建设智慧乡村，构建大数据服务平台",
          productDesc:
              "党的十九大报告指出，农业、农村、农民问题是关系国计民生的根本问题，必须始终坚持解决好农业问题，把农村和农民放在党的工作的首位，实施乡村振兴战略。党中央、国务院先后发布了中央一号文件，",
          createTime: "2021-12-28",
        },
      ],
      news_list: [
        {
          title: '【数字乡村看龙江】探秘镜泊湖畔数字化乡村"西安样板"',
          newsDesc:
              "央广网牡丹江3月28日消息（记者马俊玮 实习生张萌）海呈蓝，湖为清，水的灵性造就镜泊湖风韵隽秀，而镜泊湖畔的黑龙江省牡丹江市，凭借数字化改革打造出了惠农兴村的数字乡村“西安样板”。",
          icon: require("../../assets/image/index/news/1.jpg"),
          createTime: "2022-03-28",
        },
        {
          title: "胡春华强调 巩固拓展脱贫攻坚成果 加快全面推进乡村振兴",
          newsDesc:
              "新华社昆明3月27日电 全国巩固拓展脱贫攻坚成果同乡村振兴有效衔接暨乡村振兴重点帮扶县工作推进会27日在云南曲靖召开。中共中央政治局委员、国务院副总理胡春华出席会议并讲话。他强调，要深入学习贯彻习近平总书记关于巩固拓展脱贫攻坚成果同乡村振兴有效衔接的一系列重要讲话精神，以更加扎实的工。",
          img_url: require("../../assets/image/index/news/1.jpg"),
          createTime: "2022-03-28",
        },
        {
          title: "为乡村振兴战略“铺路架桥”",
          newsDesc:
              "   “小康路上决不让任何一个地方因交通而掉队”，这是党的十八大以来交通扶贫的庄严承诺。在乡村振兴中，乡村交通基础设施的地位十分重要。通畅的乡村道路不仅能够方便村民出行、改善农村生活条件，还可促进农村经济发展、增加农民收入，并在很大程度上改善农村消费环境、增加村民消费需求。　   “四好农村路”让党心与民心更亲近。党的十八大以来，习近平总书记站在党和国家事业发展全局的高度，多次就农村公路...",
          img_url: require("../../assets/image/index/news/1.jpg"),
          createTime: "2022-03-25",
        },
        {
          title: "政策引导数字乡村发展正逢其时",
          newsDesc:
              "在刚刚结束的全国两会上，有关推进乡村振兴、数字乡村建设的议题受到了两会代表的热议。今年的《政府工作报告》明确提出，建设数字信息基础设施，逐步构建全国一体化大数据中心体系，推进5G规模化应用，促进产业数字化转型，发展智慧城市、数字乡村。2022年是巩固拓展脱贫攻坚成果同乡村振兴有效衔接的关键一年。农业农村部部长唐仁健在今年两会的“部长通道”接受媒体提问时也强调，要抓住产业就业这个根本，补上技术...",
          img_url: require("../../assets/image/index/news/1.jpg"),
          createTime: "2022-03-23",
        },
      ],
      l: require("@/assets/img/index/left-jiantou_png.png"),
      l_c: require("@/assets/img/index/left-jiantouxuanzhong_png.png"),
      r: require("@/assets/img/index/right-jiantou_png.png"),
      r_c: require("@/assets/img/index/right-jiantouxuanzhong_png.png"),
      advantage: [
        // {
        //   title: "拥有一批科研人才",
        //   content:
        //     "专注数字乡村、乡村振兴、智慧农业服务的团队：精通人工智能、大数据分析、遥感卫星技术、云计算、元宇宙算法。",
        //   icon: require("@/assets/img/index/company/tuandui.icon_png.png"),
        // },
        {
          title: "行业地位",
          content:
              "数字乡村领导厂商，数字乡村建设标准制定企业之一，数字农业专家企业",
          icon: require("@/assets/img/index/company/tuandui.icon_png.png"),
        },
        {
          title: "售后服务",
          content:
              "专业力量覆盖全国的销售及服务网络，可为全国各地客户提供优质服务。",
          icon: require("@/assets/img/index/company/fuwu.icon_png.png"),
        },
        {
          title: "公司优势-解决方案",
          content:
              "“一战式”数字化服务、完备的软硬产品开发能力，提供数字乡村、乡村振兴等专业解决方案，融合业务场景与创新技术，重塑行业服务形态。",
          icon: require("@/assets/img/index/company/fangan.icon_png.png"),
        },
        // {
        //   title: "合作伙伴",
        //   content:
        //     "赋能生态合作伙伴，助力行业数字化创新，丰富合作伙伴体系，为客户构建特色行业解决方案，携手共赢。",
        //   icon: require("@/assets/img/index/company/huoban.icon_png.png"),
        // },
      ],
      policy: [
        {
          title: "积极与组织同行",
          content:
              "积极与组织同行，响应号召，认真学习组织思想，赋能乡村振兴，数字乡村建设全面实施提供专业的解决方案。",
        },
        {
          title: "技术创新实施落地",
          content:
              "基于5G互联网、北斗卫星导航技术、大数据、云计算、人工智能和物联网等技术自主研发了多个大型智慧软件云平台",
        },
        {
          title: "致力于服务好各部门及合作伙伴",
          content:
              "我们专注数字乡村，提供咨询、设计、软件开发、信息化整体解决方案，赋能乡村振兴战略实施。",
        },
      ],
    };
  },
  watch: {},
  created() {
    this.getCarouselList();
    this.getPlamApplicationList();
    this.getNewsList();
    this.getProductDescList();
    this.getWebsiteMeta();
  },
  computed: {
    replace_domain() {
      return (v) => {
        return v.replace(/8.129.187.206/g, "www.hcwisdom888.com");
      };
    },
  },
  methods: {
    // 文章
    toArticle(id) {
      let {href} = this.$router.resolve({
        path: "../article",
        query: {
          id,
        },
      });
      window.open(href, "_blank");
    },
    // 产品介绍更多
    toProducts() {
      let {href} = this.$router.resolve({
        path: "../product",
      });
      window.open(href, "_blank");
    },
    // 新闻更多
    toNews() {
      let {href} = this.$router.resolve({
        path: "../news",
      });
      window.open(href, "_blank");
    },
    toProduct(name) {
      if (name == "") return;

      let {href} = this.$router.resolve({
        path: "../scheme",
        query: {
          title: name,
        },
      });
      window.open(href, "_blank");
    },
    // 方案
    toScheme(title) {
      if (title == "无") return;
      this.$router.push({
        path: "../scheme",
        query: {
          title,
        },
      });
    },
    toNewsScheme(title) {
      let {href} = this.$router.resolve({
        path: "../scheme",
        query: {
          title,
        },
      });
      window.open(href, "_blank");
    },
    // 新闻

    // 获取轮播
    getCarouselList() {
      this.request({
        url: this.$api.getCarouselList,
        data: {},
      }).then((res) => {
        this.swiper_list = res.data;
      });
    },
    // 获取智慧软件
    getPlamApplicationList() {
      this.request({
        url: this.$api.getPlamApplicationList,
        data: {},
      }).then((res) => {
        // if (res.code == 0) this.application_list = res.data;
      });
    },
    // 获取新闻资讯列表
    getNewsList() {
      this.request({
        url: this.$api.getNewsList,
        data: {
          pageNum: 1,
          pageSize: 4,
          searchKey: "",
        },
      }).then((res) => {
        if (res.code == 0) this.news_list = res.data.list;
      });
    },
    //获取产品介绍列表
    getProductDescList() {
      this.request({
        url: this.$api.getProductDescList,
        data: {
          pageNum: 1,
          pageSize: 5,
          searchKey: "",
        },
      }).then((res) => {
        if (res.code == 0) this.product_list = res.data.list;
      });
    },
    getWebsiteMeta() {
      this.request({
        url: this.$api.getWebsiteMeta,
        data: {},
      }).then((res) => {
        document.title = res.data.meta;
      });
    },

    app_change(e) {
      this.$nextTick(() => {
        this.application_index = e;
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import url("./index.less");
</style>
